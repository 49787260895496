import React, { Fragment, Component } from "react";
import IsFetching from "../modules/is-fetching";
// import Notification from "./notification";
import PropTypes from "prop-types";
import "./Launcher.scss";


export default class Launcher extends Component {
  constructor(props) {
    super(props);
    this.closeLauncher = this.closeLauncher.bind(this);
    this.closeAssistant = this.closeAssistant.bind(this);
    // this.minimizedAssistant = this.minimizedAssistant.bind(this);
    this.callAsyncData();
    this.launcher = React.createRef();
  }
  callAsyncData() {
    this.saludar();
  }

  saludar() {
    const { customParamsStates } = this.props;
    const keep_conversation = customParamsStates.getIn([
      "customParams",
      "settings",
      "keep_conversation",
    ]);
    const hc = localStorage.getItem("hc");

    if (!keep_conversation) {
      this.props.getSaludo();
    } else {
      if (!hc) {
        this.props.getSaludo();
      }
    }
  }

  closeAssistant() {
    const { closeAssistant } = this.props;
    this.notificationCDN();
    localStorage.removeItem("hcm");
    localStorage.removeItem("hc");
    const iframeContainer = document.getElementById("ifrm-assitant");
    if (iframeContainer) {
      iframeContainer.classList.remove("iframe-cognitive-assistant-container");
      iframeContainer.classList.remove("active");
    }
    this.minimizedCDN()
    closeAssistant();
  }

  minimizedCDN() {
    window.top.postMessage(
      {
        test: [
          {
            msg: "minimized"
          }
        ]
      },
      "*"
    );
  }

  openAssitantCDN() {
    window.top.postMessage(
      {
        test: [
          {
            msg: "assistant",
          },
        ],
      },
      "*"
    );
  }

  notificationCDN() {
    window.top.postMessage(
      {
        test: [
          {
            msg: "notification",
          },
        ],
      },
      "*"
    );
  }

  closeLauncher() {
    const { closeLauncher, closeHelp, openAssistant, ayudaStates , customParamsStates } = this.props;
    closeLauncher();
  
    this.openAssitantCDN();
    openAssistant();
   
    if (ayudaStates.get("open")) closeHelp();
    if (localStorage.getItem("hcm")) localStorage.removeItem("hcm");
  }

    content(
      customParamsStates,
      launcherStates,
      conversationsStates,
      mainCss,
      responsiveStates
    ) {
      if (
        customParamsStates.get(["customParams", "status"]) !== 0 &&
        conversationsStates.get("conversations").size > 0 
      ) {
        if (launcherStates.get("active")) {
          const bubble_logo = customParamsStates.getIn([
            "customParams",
            "bubble_logo",
          ]);
          let bubble = customParamsStates.getIn([
            "customParams",
            "settings",
            "bubble",
          ]);
          const currentUrl = window.location.href;
          // const assistantUrl = "https://asistente-react.neuralcomm.dev/?open=false";
          const assistantUrl = "https://asistente-ivic.coopeuch.cl/?open=false";
          const webPrivate = "https://webprivado.coopeuch.cl/#/ecd/inicio"
          // const assistantUrl = "http://localhost:3000/"
          if (currentUrl === assistantUrl || currentUrl === webPrivate) {
            bubble = false;
            
          }
          
          return (
            <Fragment>
              <div className={mainCss.MainLauncher}>              
                <React.Fragment>
                {bubble  && launcherStates.get("notification") && !localStorage.getItem("hc") ? (
                  <div
                    className={mainCss.Notification}
                    style={{ cursor: "pointer" }}
                    onClick={this.closeLauncher}
                  >
                    <span
                      dangerouslySetInnerHTML={{
                        __html: launcherStates.get("notification"),
                      }}
                    />
                  </div>
                  ) :  null }
                </React.Fragment>
         
                {launcherStates.get("notification") && bubble && bubble_logo.length > 0 ? (
                  <div className="boxBubbleLogo">
                    <img
                      className="imgBubbleLogo"
                      onClick={this.closeLauncher}
                      src={`${bubble_logo}`}
                      alt="Avatar Img"
                    />
                  </div>
                ) : (
                  // <button
                  //   ref={this.launcher}
                  //   className={mainCss.LauncherButton}
                  //   onClick={this.closeLauncher}
                  // >
                  //   <i className={mainCss.IconLauncher} />
                  // </button>
                <div></div> 
                )}
              </div>
            </Fragment>
          );
        } else if (responsiveStates.get("responsive") === "desktop") {
          return (
           // <div className={mainCss.MainLauncher}>
           <div >
              {/* <button
                ref={this.launcher}
                className={mainCss.LauncherButton + " " + mainCss.Close}
                onClick={this.closeAssistant}
              > */}
                {/* <i className={mainCss.IconClose} /> */}
              {/* </button> */}
            </div>
          );
        }
      }
      return null;
    }

    render() {
      const {
          customParamsStates,
          launcherStates,
          conversationsStates,
          mainCss,
          responsiveStates,
        } = this.props,
        colorHeader = customParamsStates.getIn(["customParams", "colorHeader"]);

      return (
        <IsFetching
          isFetching={customParamsStates.get("isFetching")}
          showChildren={true}
          colorHeader={colorHeader}
          mainCss={mainCss}
        >
          {this.content(
            customParamsStates,
            launcherStates,
            conversationsStates,
            mainCss,
            responsiveStates
          )}
        </IsFetching>
      );
    }
  }

  Launcher.propTypes = {
    customParamsStates: PropTypes.any.isRequired,
    saludoStates: PropTypes.any.isRequired,
    launcherStates: PropTypes.any.isRequired,
    generalStates: PropTypes.any.isRequired,
    closeLauncher: PropTypes.func.isRequired,
  };
